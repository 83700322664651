<template>
  <div>
    <el-form
        ref="form"
        label-width="0"
        class="view pa24"
        label-position="left"
    >
      <el-form-item>
        <p style="color: #606266;font-family: Source Han Sans CN;font-size:26px;text-align: center;font-weight: bold;width: 80%;margin: 0 auto">
          {{ detailsDate.name }}</p>
      </el-form-item>
      <el-form-item style="margin-top: 30px">
        <div style="display: flex;justify-content: space-between;font-size: 18px">
          <div style="display: flex"><img src="../../assets/images/square_head.png"
                                          style="width: 30px;height: 30px;align-self: center" alt="">
            <p style="color: #606266;font-weight: bold;font-size: 18px;margin: 0 10px">{{ detailsDate.companyName }}</p>
          </div>
          <div style="color: #606266;font-size: 14px;"><i class="el-icon-time"></i> {{ detailsDate.createTime }}</div>
          <div></div>
        </div>
      </el-form-item>
      <hr
          style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 10px 0;
        "
      />

      <div style="display: flex;margin-top: 30px">
        <div style="border-right: 1px solid #e4e4e4;padding-right: 50px;padding-left: 20px;width: 100%">
          <el-form-item>
            <p style="padding: 20px" v-html="detailsDate.content"></p>
          </el-form-item>
          <hr
              style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 10px 0;
        "
          />
        </div>
        <div style="width: 110px">
          <div style="padding-left: 30px;height: 80px;margin-bottom: 15px">
            <el-image
                style="width: 80px; height: 80px;border-radius: 10px"
                :src="detailsDate.img"
                :preview-src-list="urlList">
            </el-image>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {queryCompanyDynamicById} from "@/api/square";
export default {
  name: "dynamicDetails",
  data() {
    return {
      currentPage: 1,
      pageNum: 1,
      pageSize: 10,
      detailsDate: {},
      dialogImageUrl: "",
      dialogVisible: false,
      isShow: false,
      discussPageList: [],
      total: 0, //总条数
      dynamicId: '',
      urlList: [],
    };
  },

  created() {
    let dynamicId = this.$route.query.dynamicId
    this.dynamicId = dynamicId
    this.getDetails(dynamicId)
  },

  methods: {
    getDetails(dynamicId) {
      let data = {
        dynamicId: dynamicId
      }
      queryCompanyDynamicById(data)
          .then(res => {
            let data = res.data
            data.createTime = this.renderTime(data.createTime)
            this.urlList = [data.img]
            this.detailsDate = data
          })
          .catch(err => {
            console.log(err)
          })
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
  },
};
</script>
<style>
.discuss_wrap {

}

.discuss_title {
  display: flex;
  padding-left: 20px;
}

.discuss_title > img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.discuss_title > div {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*font-size: 18px;*/
  /*align-self: center;*/
  margin-left: 10px;
}

.discuss_title > div > p:first-child {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #606266;
}

.discuss_title > div > p:nth-child(2) {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8D8D8D;
}

.discuss_button {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 20px;
}

.discuss_button > p:first-child {
  margin-left: 40px;
  margin-right: 30px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #606266;
  flex: 1;
}

.discuss_button > p:nth-child(2) {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #606266;
}

</style>



